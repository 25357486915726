<template>
  <div id="app">
    <keep-alive include="UsaChild">
      <router-view v-if="$route.meta.keepAlive"></router-view >
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view >
  </div>
</template>
<script>
  export default {
    data() {
      return {
      };
    },
    computed:{
    },
    methods:{
    }
  };
</script>
<style>
*{
  margin: 0;
  padding: 0;
}
html,body,#app{
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 800px) {
  html{
  overflow-x: hidden;
}
}
</style>
