<template>
  <div class="Home">
    <el-container style="height: 100%;">
      <el-header style="padding: .3rem;">
        <div class="header-box">
          <div class="Home-header-left">
            <img class="header_img1" src="@/imgs/商标.png" />
            <h1 id="title">Instructions For Use</h1>
          </div>
          <div class="Home-header-right">
            <el-select v-model="value" placeholder="Select Country or Region" @change="open($event)"
              value-key="areaCode" popper-class="Home-header-select" filterable size="medium" no-data-text="No data"
              style="margin-top: .1rem;">
              <el-option v-for="item in options" :key="item.areaCode" :label="item.area" :value="item"
                @click.native="standbyOpen">
              </el-option>
            </el-select>
          </div>
        </div>
      </el-header>
      <div class="radio-box">
        <ul>
          <li class="radio-mobile" style="padding: .2rem;"><span class="radio-icon"></span>Please select the target region
            from the map or through
            the regions list on the top right corner!</li>
        </ul>
      </div>

      <div style="flex-grow: 1;"></div>
      <div class="area-select">
        <h3 class="label">Select Country or Region</h3>
        <div class="selector">
          <div class="area-option" :style="{ width: (selectedArea ? '47%' : '100%') }">
            <div v-for="  areaName, index   in   areas  "
              :class="{ option: true, underline: index < areas.length - 1, active: areaName === selectedArea }"
              @click="selectedArea = areaName">
              {{ areaName }}
              <span class="right">></span>
            </div>
          </div>
          <div class="breakline" :style="{ display: !selectedArea ? 'none' : 'block' }"></div>
          <div class="market-option" :style="{ display: !selectedArea ? 'none' : 'block' }">
            <div v-for="  item, index   in   allMarket[selectedArea.toLowerCase().replace(' ', '')]  "
              :class="{ option: true, underline: index < allMarket[selectedArea.toLowerCase().replace(' ', '')].length - 1 }"
              @click="open({ dialogState: true, areaCode: item.areaCode, area: item.area })">
              {{ item.area }}
            </div>
          </div>
          <div style="clear: both;"></div>
        </div>
      </div>
      <div class="map-earth">
        <el-image style="width: 100%;" :src="url" @load="imgShow" />
        <div class="tag p-africa">
          <popover @UpdateDialog="OnUpdateDialog" :msg="['AFRICA', allMarket.africa]">
            <div class="click-box">
              <div class="tooltip">AFRICA</div>
              <div class="iconfont icon-dingweixiao iconfont-Africa" :class="{ showMark: imgStates }"></div>
            </div>
          </popover>
        </div>
        <div class="tag p-europe">
          <popover @UpdateDialog="OnUpdateDialog" :msg="['EUROPE', allMarket.europe]">
            <div class="click-box">
              <div class="tooltip">EUROPE</div>
              <div class="iconfont icon-dingweixiao iconfont-europe" :class="{ showMark: imgStates }"></div>
            </div>
          </popover>
        </div>
        <div class="tag p-asia">
          <popover @UpdateDialog="OnUpdateDialog" :msg="['ASIA', allMarket.asia]">
            <div class="click-box">
              <div class="tooltip">ASIA</div>
              <div class="iconfont icon-dingweixiao iconfont-asia" :class="{ showMark: imgStates }"></div>
            </div>
          </popover>
        </div>
        <div class="tag p-china">
          <popover @UpdateDialog="OnUpdateDialog" :msg="['CHINA', allMarket.china]">
            <div class="click-box">
              <div class="tooltip">CHINA</div>
              <div class="iconfont icon-dingweixiao iconfont-china" :class="{ showMark: imgStates }"></div>
            </div>
          </popover>
        </div>
        <div class="tag p-usa">
          <popover @UpdateDialog="OnUpdateDialog" :msg="['NORTH AMERICA', allMarket.northamerica]">
            <div class="click-box">
              <div class="tooltip">NORTH AMERICA</div>
              <div class="iconfont icon-dingweixiao iconfont-usa" :class="{ showMark: imgStates }"></div>
            </div>
          </popover>
        </div>
        <div class="tag p-south-america">
          <popover @UpdateDialog="OnUpdateDialog" :msg="['SOUTH AMERICA', allMarket.southamerica]">
            <div class="click-box">
              <div class="tooltip">SOUTH AMERICA</div>
              <div class="iconfont icon-dingweixiao iconfont-SouthAmerica" :class="{ showMark: imgStates }">
              </div>
            </div>
          </popover>
        </div>
        <div class="tag p-oceania">
          <popover @UpdateDialog="OnUpdateDialog" :msg="['OCEANIA', allMarket.oceania]">
            <div class="click-box">
              <div class="tooltip">OCEANIA</div>
              <div class="iconfont icon-dingweixiao iconfont-Oceania" :class="{ showMark: imgStates }"></div>
            </div>
          </popover>
        </div>
      </div>
      <div style="flex-grow: 1;"></div>
      <el-footer style="display: flex; flex-direction: column;">
        <div style="flex-grow: 1;"></div>
        <div class="Home-footer" ref="footer">
          <div class="footer">
            <div class="Home-footer-title" v-if="buttoms[0]">
              {{ buttoms[0].address_title }}
            </div>
            <div class="Home-footer-content" v-if="buttoms[0]">
              <div class="x003">
                <p>{{ buttoms[0].address_place }}</p>
                <p>{{ buttoms[0].address_nation }}</p>
                <p>{{ buttoms[0].address_area }}</p>
              </div>
              <p>
                TEL:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ buttoms[0].tel }}
              </p>
              <p>E-mail:&nbsp;&nbsp;{{ buttoms[0].email }}</p>
            </div>
          </div>
          <div class="footer2 footer">
            <div class="Home-footer-title" v-if="buttoms[0]">
              {{ buttoms[1].address_title }}
            </div>
            <div class="Home-footer-content" v-if="buttoms[0]">
              <div class="x003">
                <p>{{ buttoms[1].address_place }}</p>
                <p>{{ buttoms[1].address_nation }}</p>
                <p>{{ buttoms[1].address_area }}</p>
              </div>
              <p>
                TEL:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ buttoms[1].tel }}
              </p>
              <p>E-mail:&nbsp;&nbsp;{{ buttoms[1].email }}</p>
            </div>
          </div>
          <div class="footer3 footer">
            <div class="Home-footer-title" v-if="buttoms[0]">
              {{ buttoms[2].address_title }}
            </div>
            <div class="Home-footer-content" v-if="buttoms[0]">
              <div class="x003">
                <p>{{ buttoms[2].address_place }}</p>
                <p>{{ buttoms[2].address_nation }}</p>
                <p>{{ buttoms[2].address_area }}</p>
              </div>
              <p>
                TEL:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ buttoms[2].tel }}
              </p>
              <p>E-mail:&nbsp;&nbsp;{{ buttoms[2].email }}</p>
            </div>
          </div>
        </div>
        <div style="flex-grow: 1;"></div>
        <div class="footer-number">
          Copyright © MICRO-TECH (Nanjing) Co., Ltd. <a href="https://beian.miit.gov.cn">苏ICP备12011765号</a>
        </div>
      </el-footer>
    </el-container>
    <dialogs v-bind="tolinks" @cancelDialog="cancelDialog"></dialogs>
  </div>
</template>
<style scoped></style>
<script>
import { gitListMarket } from "@/api/getData.js";
import popover from "./Popover.vue";
import dialogs from "./Dialog.vue";

export default {
  components: {
    popover,
    dialogs,
  },
  data() {
    return {
      url: require("@/assets/img/world-map.svg"),
      fit: "fill",
      value: "",
      options: [],
      imgStates: false,
      tolinks: {
        dialogState: false,
        areaCode: '',
        area: "",
      },
      email: "icontact@micro-tech-europe.com",
      buttoms: [
        {
          id: 1,
          address: "Nation Hi-Tech Zone, NanJing,China,210032",
          tel: "+86-25-58609879，58646393",
          email: "info@micro-tech.com.cn",
          address_title: "Micro-Tech (NANJING) CO., LTD.",
          address_nation: "National Hi-Tech Industrial Development Zone",
          address_area: "Nanjing 210032, Jiangsu Province, PRC",
          address_place: "No. 10, Gaoke Third Road, Nanjing",
        },
        {
          id: 2,
          address: "Nation Hi-Tech Zone, NanJing,China,210032",
          tel: "+49 (0)211 73 27 626-0",
          email: "contact@micro-tech-europe.com",
          address_title: "Micro-Tech Europe GmbH.",
          address_nation: "40472 Düsseldorf, Germany",
          address_area: "",
          address_place: "Mündelheimer Weg 36",
        },
        {
          id: 3,
          address: "Nation Hi-Tech Zone, NanJing,China,210032",
          tel: "(+1) 877 552 4027",
          email: "eifu@mtendoscopy.com",
          address_title: "Micro-Tech USA Inc.",
          address_nation: "Ann Arbor, MI 48104 USA",
          address_area: "",
          address_place: "2855 Boardwalk Drive",
        },
      ],
      areas: ["Africa", "Asia", "China", "Europe", "North America", "Oceania", "South America"],
      selectedArea: "",
      allMarket: {},
    };
  },
  methods: {
    open(e) {
      console.log(e)
      if (!e) return;
      this.tolinks.dialogState = true;
      this.tolinks.areaCode = e.areaCode;
      this.tolinks.area = e.area;
    },
    // 检测重复点击
    standbyOpen() {
      if (!this.tolinks.dialogState && Object.keys(this.value).length != 0) {
        this.tolinks.dialogState = true;
      }
    },
    // 图片加载检查
    imgShow() {
      this.imgStates = true;
    },
    // Dialog状态变更
    OnUpdateDialog(val) {
      this.tolinks.dialogState = val.dialogState;
      this.tolinks.areaCode = val.areaCode;
      this.tolinks.area = val.area;
    },
    // 关闭Dialog
    cancelDialog(val) {
      this.tolinks.dialogState = val;
    },
    // select 将嵌套数组进行展开
    searchFormat(data) {
      let newdata = [];
      Object.keys(data).forEach((item) => {
        let old = data[item].map((value) => {
          let area = value.area;
          let areaCode = value.areaCode;
          return { area, areaCode };
        });
        newdata.push(...old);
      });
      this.options = newdata.sort((x, y) => {
        return (x.area).localeCompare(y.area)
      });
    },
    // 图片热区 market ，单独为每个州赋值
    MarketFormat(data) {
      let newdata = {};
      data.forEach((item) => {
        switch (item.continent) {
          case 1:
            newdata.asia = item.ebAreaList;
            break;
          case 2:
            newdata.southamerica = item.ebAreaList;
            break;
          case 3:
            newdata.northamerica = item.ebAreaList;
            break;
          case 4:
            newdata.oceania = item.ebAreaList;
            break;
          case 5:
            newdata.europe = item.ebAreaList;
            break;
          case 6:
            newdata.africa = item.ebAreaList;
            break;
          case 8:
            newdata.china = item.ebAreaList;
            break;
        }
      });
      this.allMarket = newdata;
      // select下拉框，数据扁平化处理
      this.searchFormat(newdata);
    },

    // 获取 area 数据
    async gitMarket() {
      try {
        const data = await gitListMarket();
        if (data.status == 200) {
          this.MarketFormat(data.data.rows);
        }
      } catch (error) {
        // console.error(error);
      }
    },
  },

  // 钩子函数 在页面渲染完成后调用方法获取数据
  mounted() {
    this.gitMarket();
  },
};
</script>
<style lang="scss" scoped>
.Home {
  height: 100%;
}

// header 布局
.el-header {
  padding: 0.1rem 8%;
  border-bottom: 2px solid rgba($color: #838080b4, $alpha: 0.1);

  .header-box {
    width: 100%;
    display: flex;
    box-sizing: content-box;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    height: 100%;
    line-height: 50px;

    .Home-header-left {
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-end;

      .header_img1 {
        height: 85px;
        margin-right: 0.2rem;
      }

      h1 {
        font-size: 45px;
        white-space: nowrap;
        align-self: center;
        font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
        font-weight: lighter;
        vertical-align: middle;
        letter-spacing: 1px;
        color: rgb(248, 166, 14);
      }
    }

    .Home-header-right {
      height: 100%;
      float: right;
      vertical-align: bottom;

      h4 {
        margin: 0;
        padding: 0 30px;
        font-size: 30px;
        // margin-top: 15px;
      }

      .search-btn {
        height: 0.74rem;
        width: 0.74rem;
        margin: 0 0.1rem;
        border-radius: 5px;
        background: #f8a60e;
      }
    }
  }
}

.footer-number {
  padding-top: 0.5rem;
  text-align: center;
  font-size: 0.25rem;
  font-family: PingFangSC-Regular, sans-serif;

  a {
    text-decoration: none;
    color: rgb(247, 244, 244, 0.822);
  }
}

.click-box {
  position: relative;

  .tooltip {
    top: -50%;
    left: 50%;
    font-size: .32rem;
    padding: 6px 10px;
    color: white;
    position: absolute;
    white-space: nowrap;
    transform: translateX(-50%);
    background-color: #06328b;
  }

  .tooltip::after {
    position: absolute;
    content: "";
    display: block;
    width: 0px;
    height: 0px;
    border-width: 15px;
    border-style: solid;
    border-color: transparent;
    border-top-color: #06328b;
    left: 50%;
    transform: translateX(-50%);
    bottom: -50%;
  }
}

// el-footer 默认高度修改
.el-footer {
  height: auto !important;
  font-family: PingFangSC-Regular, sans-serif;
  background: rgba($color: #000000, $alpha: 0.8);
  color: rgba(247, 244, 244, 0.822);
  padding: .2rem .3rem .4rem;
  flex-grow: 2;

  .Home-footer {
    display: flex;
    flex-direction: row;

    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 0.25rem;
    line-height: 0.37rem;

    .footer {
      margin: 0.2rem auto 0.2rem 0;
      @media all and (max-width: 820px) {
        width: 100%;
      }
    }

    .Home-footer-title {
      color: rgb(235, 157, 13);
      font-size: 0.37rem;
      line-height: 0.62rem;
      margin: 0.2rem 0;
    }

    .x003 {
      margin-bottom: 0.1rem;
    }
  }

  .foot-under {
    // background: #000000;
    width: 100%;
    // color: #a5a2a2;
    // font-size: 15px;
    line-height: 0.62rem;
    padding: 0.2rem 0;
    font-family: PingFangSC-Semibold, sans-serif;
  }
}

// mark图标样式
.iconfont {
  color: #004198;
  opacity: 0;
  background-image: url("../../imgs/mark.png");
  background-repeat: no-repeat;
  background-size: 60% 70%;
  background-position: center;
}

// 英国
.iconfont-british {
  width: 60px;
  height: 64px;
}

// 欧洲
.iconfont-europe {
  width: 80px;
  height: 94px;
}

// 中国
.iconfont-china {
  width: 90px;
  height: 108px;
}

// 亚洲
.iconfont-asia {
  width: 80px;
  height: 98px;
}

// 北美
.iconfont-usa {
  width: 100px;
  height: 114px;
}

// 非洲
.iconfont-Africa {
  width: 80px;
  height: 98px;
}

// 太平洋
.iconfont-PacificOcean {
  width: 80px;
  height: 114px;
}

// 南美
.iconfont-SouthAmerica {
  width: 90px;
  height: 102px;
}

// 大洋洲
.iconfont-Oceania {
  width: 100px;
  height: 114px;
}

// mark 缓入动画
@keyframes show {
  0% {
    opacity: 0;
    transform: scale(0.5, 0.5);
    transform: translateY(6px);
  }

  85% {
    opacity: 1;
    transform: scale(1.2, 1.2);
    transform: translateY(-5px);
  }

  100% {
    opacity: 1;
    transform: scale(1, 1);
    transform: translateY(0);
  }
}

.showMark {
  animation: show 0.6s ease-out;
  animation-fill-mode: forwards;
}

// 广播样式调整
.radio-box {
  ul {
    list-style: none;
    color: #4F5051;
    font-size: 0.35rem;
    position: relative;
    overflow: hidden;
    background-color: #EDF4FA;

    li {
      display: block;
      // width: 100%;
      height: 100%;
      text-align: center;
      white-space: nowrap;

      .radio-icon {
        vertical-align: middle;
        display: inline-block;
        height: 60px;
        width: 30px;
        margin-right: 10px;
        background-image: url('../../imgs/dengpao.png');
        background-size: 80% 45%;
        background-repeat: no-repeat;
        background-position: center 40%;
      }
    }
  }
}

@media all and (max-width: 1000px) {

  // 移动端滚动广播
  .radio-mobile {
    animation: 25s rollAnimation linear infinite normal;
    animation-fill-mode: forwards;
  }

  // 移动端滚动广播动画
  @keyframes rollAnimation {
    0% {
      transform: translateX(100%);
    }

    50% {
      transform: translateX(0%);
    }

    100% {
      transform: translateX(-110%);
    }
  }
}


.map-earth {
  position: relative;
  width: 100%;

  img {
    align-items: center;
  }

  .tag {
    width: 80px;
    position: absolute;
  }

  .p-africa {
    left: 46%;
    top: 44%;
  }

  .p-europe {
    left: 46%;
    top: 25%;

    @media all and(max-width: 1200px) {
      top: 22%;
    }

    @media all and(max-width: 900px) {
      top: 18%;
    }

    @media all and(max-width: 600px) {
      top: 15%;
    }
  }

  .p-asia {
    left: 55%;
    top: 35%;

    @media all and(max-width: 1200px) {
      top: 30%;
    }

    @media all and(max-width: 800px) {
      left: 54%;
      top: 26%;
    }

    @media all and(max-width: 700px) {
      top: 23%;
    }

    @media all and(max-width: 600px) {
      top: 20%;
    }
  }

  .p-china {
    left: 62%;
    top: 33%;

    @media all and(max-width: 1000px) {
      top: 27%;
    }

    @media all and(max-width: 700px) {
      left: 61%;
      top: 22%;
    }
  }

  .p-usa {
    left: 25%;
    top: 22%;

    @media all and(max-width: 800px) {
      left: 24%;
      top: 18%;
    }

    @media all and(max-width: 600px) {
      top: 15%;
    }
  }

  .p-south-america {
    left: 33%;
    top: 64%;

    @media all and(max-width: 800px) {
      left: 32%;
      top: 57%;
    }

    @media all and(max-width: 600px) {
      left: 31%;
      top: 53%;
    }
  }

  .p-oceania {
    left: 71%;
    top: 70%;

    @media all and(max-width: 1600px) {
      top: 67%;
    }

    @media all and(max-width: 1200px) {
      top: 64%;
      left: 69%;
    }

    @media all and(max-width: 1000px) {
      top: 61%;
    }

    @media all and(max-width: 800px) {
      top: 55%;
    }

    @media all and(max-width: 600px) {
      top: 50%;
      left: 67%;
    }
  }

}

.area-select {
  display: none;
  margin: auto;
  width: 80%;
  padding: .5rem .1rem;
  font-size: .35rem;

  .label {
    padding-bottom: .3rem;
    font-family: PingFangSC-Medium;
    font-weight: 500;
  }

  .area-option {
    display: inline-block;
  }

  .market-option {
    width: 47%;
    height: 6.2rem;
    float: right;
    overflow-y: auto;
    background-color: rgba(237, 244, 250, 0.43);

    .option {
      padding: .1rem .3rem;
    }
  }

  .breakline {
    border-left: 1px solid #E4E8E9;
    position: absolute;
    height: 6.2rem;
    left: 50%;
    top: .2rem;
  }

  .active {
    color: rgba(244, 136, 51, 1);
    background-color: rgba(237, 244, 250, 0.43);
  }

  .option {
    text-align: left;
    font-family: PingFangSC-Regular;
    white-space: nowrap;
    padding: .2rem;

    .right {
      float: right;
      color: #B4B4B4;
    }
  }

  .underline {
    border-bottom: 1px solid #E4E8E9;
  }

  .selector {
    border: 1px solid rgba(244, 136, 51, 1);
    padding: .2rem;
    border-radius: .1rem;
    position: relative;
  }
}


// 当宽度小于1000时展示为选择框
@media all and (max-width: 1000px) {
  .radio-box {
    display: none !important;
  }

  .Home-header-right {
    display: none !important;
  }

  .area-select {
    display: block !important;
  }

  .map-earth {
    display: none;
  }

  // 当横屏状态时，选择框横向展示
  @media all and (orientation: landscape) and (min-width: 650px) {
    .area-select {
      width: calc(100% - .4rem);
      padding: .2rem !important;


      .underline {
        border-right: 1px solid #E4E8E9;
      }

      .area-option {
        width: 100% !important;
        display: flex !important;
        align-items: center;

        .option {
          padding: 0 0 0.2rem;
          flex-grow: 1;
          text-align: center;
        }
      }

      .market-option {
        width: 100%;
        height: 3rem;
        font-size: .25rem;
        display: block !important;
      }

      .breakline {
        display: none !important;
      }

    }


    .option {
      border-bottom: 1px solid #E4E8E9;

      .right {
        display: none;
      }
    }
  }
}
</style>
