import axios from "axios";

export function downloadPdf(file, name) {
    axios({
        method: "get",
        url: file,
        responseType: "blob"
    }).then(function(response) {
        // console.log("11111111" + response)
        const link = document.createElement("a");
        let blob = new Blob([response.data], { type: response.data.type });
        if (window.navigator.msSaveBlob != undefined) {
            window.navigator.msSaveBlob(blob, `${name}.pdf`)
        } else {
            // console.log("2222222" + blob)
            let url = URL.createObjectURL(blob);
            link.href = url;
            // link.href = file;
            link.download = name;
            link.setAttribute('target', '_blank')
            document.body.appendChild(link)
            link.click();
            document.body.removeChild(link)
            window.URL.revokeObjectURL(url)

        }
    }).catch(err => {
        // console.log("下载错误" + err)
    })
}