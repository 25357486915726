import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home/Home";
import QueryArea from "../views/QueryArea/index";
import Test from "../views/Test/test03/Parent";
import ComPdf from "../views/QueryArea/Components/ComPdf"
import ComAudio from "../views/QueryArea/Components/ComAudio"
import ComVideo from "../views/QueryArea/Components/ComVideo.vue"
Vue.use(VueRouter);
const routes = [{
        path: "/",
        name: "Home",
        component: Home
    },
    {
        path: "/QueryArea",
        name: "QueryArea",
        component: QueryArea,
        meta: {
            keepAlive: true
        }
    },
    {
        path: '/ComPdf',
        name: "ComPdf",
        component: ComPdf,
    },
    {
        path: '/ComAudio',
        name: 'ComAudio',
        component: ComAudio,
    },
    {
        path: '/ComVideo',
        name: 'ComVideo',
        component: ComVideo,
    },
    {
        path: "/test",
        name: "test",
        component: Test,
    },
];

const router = new VueRouter({
    routes,
    // mode: 'history'
});
// router.afterEach((to, from) => {
//     console.log(to)
// })

export default router;