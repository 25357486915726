<template>
    <div style="width: 100%" class="pdfBox">
        <!-- <div>
            <router-link to="/QueryArea" slot="left">
                <el-button icon="el-icon-arrow-left" circle></el-button>
            </router-link>
        </div> -->
        <!--<object :data="pageUrl"  style="width: 100%; height: 100%">测试文本！</object>-->
        <object
            id="pdfViewer"
            class="viewer"
            type="application/pdf"
            v-if="pageUrl"
            :data="pageUrl"
            style="width: 100%">
            <p>抱歉！您的浏览器不支持浏览pdf格式文件，请更新浏览器后重新尝试!</p>
        </object>
        <!--<iframe :src="pageUrl" frameborder="0"></iframe>-->
        <div id="pdfViewport" v-if="!pageUrl"></div>
    </div>
</template>
<script>
  import Pdfh5 from "pdfh5";
  import "pdfh5/css/pdfh5.css";
export default {
    props:{
        msg:{
            type:String,
            default:''
        },
        // url:{
        //     type:String,
        //     default:'https://www.gjtool.cn/pdfh5/git.pdf'
        // }
    },
    data() {
        return {
            // pageUrl: 'http://www.cztouch.com/upfiles/soft/testpdf.pdf'
            pageUrl: "",
            Pdfh5:null
        }
    },
    mounted(){
        this.judgeRouter()
    },
    watch: {
        msg: function () {
            this.judgeRouter();
        }
    },
    methods: {
        // 客户端设备判断
        judgeRouter(){
            if(!this.$route.query.file){
                this.pageUrl = this.msg
            }else{
                this.pdfh5 = new Pdfh5("#pdfViewport",{
                    pdfurl: this.$route.query.file
                })
                this.pageUrl = false
            }
        }
    }
}
</script>
<style>
    .pdfBox{
        height: 100%;
    }
</style>