import axios from "../request";


//通过编号查询返回器械的具体信息
export const getDetail = (ref) => {
    const requests = axios;
    requests.headers = {
        "Content-Type": "application/json;charset=UTF-8"
    }
    return requests.get("/web-api/search/searchDocument", {
        params: {
            "productName": ref.productName,
            "productComment": ref.productComment,
            "ifuCode": ref.ifuCode,
            "datetime": ref.datetime,
            "areaCode": ref.areaCode
        },
    });
}

// 首页
export function gitListMarket() {
    return axios.get('/web-api/map/info')
}
// 详情页模糊查询
export function fuzzyList(value, areaCode) {
    return axios.get(`/web-api/search/fuzzyMatch?name=${value}&areaCode=${areaCode}`)
}

// 公告请求
export function announcement(areaCode) {
    return axios.get(`/web-api/search/searchNotice?areaCode=${areaCode}`)
}

// 下载量,预览量统计
export function pushDownload(data) {
    return axios({
        url: '/web-api/accessLog/accessIpAnalyse',
        method: 'post',
        data: data
    })
}

// 搜索量统计
export function searchCount(data) {
    return axios({
        url: '/web-api/accessLog/searchIpAnalyse',
        method: 'post',
        data: data
    })
}

// 获取访问用户所属大洲
export function getUserArea() {
    return axios({
        url: '/web-api/map/getCurrentContinent',
        method: 'get',
    })
}