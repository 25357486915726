<template>
    <div style="height: 500px; width: 100%">
        <div>
            <router-link to="/QueryArea" slot="left">
                <el-button icon="el-icon-arrow-left" circle></el-button>
            </router-link>
        </div>
        <video width="100%" height="calc(100% - 2rem)" controls="controls">
            <source :src="pageUrl" type="video/mp4" style="height: 900px; width: 100%"/>
        </video>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                pageUrl: 'https://nwyx.obs.cn-east-2.myhuaweicloud.com/190204084208765161.mp4'
            }
        },
        methods: {}
    }
</script>