<template>
  <div>
    获取子组件中输入框的值:{{ msg }}
    <child ref="child"></child>
  </div>
</template>

<script>
import child from "../test03/Child";
export default {
  components: {
    child,
  },
  data() {
    return {
      msg: "",
    };
  },
  mounted() {
    this.msg = this.$refs.child.input;
    this.$refs.child.child_method();
  },
};
</script>
