<template>
  <div></div>
</template>

<script>
export default {
  data() {
    return {
      input: "我是子组件",
    };
  },
  methods: {
    child_method() {
      console.log("我是子组件的方法");
    },
  },
};
</script>
