<template>
  <div>
    <el-dialog
    :title="title"
    :visible="dialogState"
    top="33vh"
    custom-class="rundialog fileDialog"
    :append-to-body="true"
    :before-close="cancel"
    center>
    <div class="playerBox">
        <videos v-if="fileType == 'mp4'" :msg="URL"></videos>
        <audios v-else-if="fileType == 'mp3'" :msg="URL"></audios>
        <pdf v-else-if="fileType == 'pdf'" :msg="formatUrl()"></pdf>
    </div>
    </el-dialog>
  </div>
</template>
<script>
import videos from "./ComVideo.vue"
import audios from "./ComAudio.vue"
import pdf from "./ComPdf.vue"
  export default {
      components:{
          videos,
          audios,
          pdf
      },
    props:{
      dialogState:{
        type:Boolean,
        default:false
      },
      URL:{
        type:String,
        default:''
      },
      fileType: {
          type:String,
          default:''
      },   
      title:{
        type:String,
        default:''
      }
    },
    data() {
      return {
        centerDialogVisible: true
      };
    },
    computed:{
      // formatUrl(){
      //   console.log("Dialog this.url: ", this.URL)
      //   return this.URL
      // }
    },
    methods:{
      formatUrl(){
        console.log("Dialog this.url: ", this.URL)
        return this.URL
      },
      cancel(){
          this.$emit('responseDialog',false)
      },
    }
  };
</script>