<template>
  <div>
    <el-dialog
    title="System Prompt"
    :visible="dialogState"
    top="33vh"
    custom-class="rundialog"
    :append-to-body="true"
    :before-close="cancel"
    center>
    <span>Are you sure you want to proceed to {{markedId}}?</span>
    <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toMarket(markedId)">PROCEED</el-button>
        <el-button @click="cancel">CANCEL</el-button>
    </span>
    </el-dialog>
  </div>
</template>
<script>
  export default {
    props:{
      dialogState:{
        type:Boolean,
        default:false
      },
      markedId:{
        type:String,
        default:''
      },
      market:{
        type:String,
        default:''
      }
    },
    data() {
      return {
        centerDialogVisible: true
      };
    },
    methods:{
        cancel(){
            this.$emit('responseDialog',false)
        },
        toMarket(id){
            this.$emit('responseDialog',{"markedId":id,"market":this.market})
            
        }
    }
  };
</script>