<template>
    <div>
        <el-popover placement="bottom" trigger="click" :title="msg[0] + '-market'" popper-class="home-popover"
            v-if="msg.length">
            <el-table :data="msg[1]" empty-text="No data" @cell-click="areaclick" cell-class-name="hand">
                <el-table-column prop="index" label="region">
                    <template slot-scope="scope">
                        <div>{{ scope.row.area | textformat }}</div>
                    </template>
                </el-table-column>
                <!-- <el-table-column width="150"  prop="market" label="market" class-name="boldclass">
                <template slot-scope="scope">
                    <div @click="areaclick(scope.row.marketId)">{{scope.row.name}}</div>
                </template>
            </el-table-column> -->
            </el-table>
            <slot class="popover-slot" slot="reference"></slot>
        </el-popover>
    </div>
</template>

<script>
export default {
    props: {
        msg: {
            type: Array,
            default: () => []
        }
    },
    filters: {
        textformat(value) {
            return value
        }
    },
    data() {
        return {
            dataList: [],
        };
    },
    computed: {

    },
    created() {

    },
    mounted() {

    },
    watch: {

    },
    methods: {
        // 具体市场点击事件
        areaclick(row) {
            // console.log(row)
            this.$emit('UpdateDialog', { 'dialogState': true, 'areaCode': row.areaCode, 'area': row.area })
        },
    },
};
</script>

<style scoped lang="scss"></style>
