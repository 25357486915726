<template>
  <div>
    <div class="main01">
      <div v-if="isPC">
        <el-table :data="detail" row-class-name="table-box" :header-cell-class-name="headerTableLast"
          :cell-class-name="headerTable" empty-text="No data">
          <el-table-column prop="ifuCode" label="IFU code" min-width="90px">
            <template slot-scope="scope">
              <div style="cursor: pointer" @click="preView(scope.row)">
                {{ scope.row.ifuCode }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="productName" label="Description" min-width="180px">
            <template slot-scope="scope">
              <div style="cursor: pointer" @click="preView(scope.row)">
                {{ scope.row.productName }}
              </div>
            </template>
          </el-table-column>
          <el-table-column width="80px" prop="revisionDesp" label="Rev." show-overflow-tooltip> </el-table-column>
          <el-table-column width="130px" prop="validStartDate" label="Date of Publication"></el-table-column>
          <el-table-column min-width="120px" prop="productComment" label="Remark" show-overflow-tooltip>
          </el-table-column>
          <el-table-column icon="el-icon-s-home" label="IFU" class-name="btn-box" fixed="right" width="100px">
            <template slot="header">
              <span>IFU<img class="consultWhite" src="@/imgs/consultWhite.jpg" /></span>
            </template>
            <template slot-scope="scope">
              <el-button class="onlyStyleBtn" @click="Download(scope.row)" size="small"><i
                  class="el-icon-download el-icon--right"></i></el-button>
              <el-button class="onlyStyleBtn" size="small" @click="preView(scope.row)"><i
                  class="el-icon-view el-icon--right"></i></el-button>
              <!-- type="primary" -->
            </template>
          </el-table-column>
          <!--<el-table-column icon="el-icon-s-home" label="IFU" show-overflow-tooltip>-->
          <!--<div class="">-->
          <!--<el-button @click="Download(detail[0].ref, detail[0].publication_date)" size="small"><i class="el-icon-download el-icon&#45;&#45;right"></i></el-button>-->
          <!--<el-button type="primary" @click="preView(detail[0].ref, detail[0].publication_date)" size="small"><i class="el-icon-view el-icon&#45;&#45;right"></i></el-button>-->
          <!--</div>-->
          <!--</el-table-column>-->
        </el-table>
      </div>
      <div class="moblie-show-list" v-else>
        <div v-for="item in detail" :key="item.areaCode" class="moblie-for-list">
          <div class="moblie-item moblie-ifucode">
            <div class="moblie-title">IFU code</div>
            <div>{{ item.ifuCode }}</div>
          </div>
          <div class="moblie-item dencryption">
            <div class="moblie-title">Description</div>
            <div>{{ item.productName }}</div>
          </div>
          <div class="moblie-item remark">
            <div class="moblie-title">Remark</div>
            <div>{{ item.productComment }}</div>
          </div>
          <div class="moblie-item rev">
            <div class="moblie-title">Rev.</div>
            <div>{{ item.revisionDesp }}</div>
          </div>
          <div class="moblie-item date">
            <div class="moblie-title">Date of Publication</div>
            <div>{{ item.validStartDate }}</div>
          </div>
          <div class="moblie-item handle">
            <div class="moblie-title">IFU <img class="consultWhite" src="@/imgs/consultWhite.jpg" /></div>
            <div><span @click="preView(item)"><i class="el-icon-view el-icon--right"></i></span> <span
                @click="Download(item)"><i class="el-icon-download el-icon--right"></i></span></div>
          </div>
        </div>
      </div>
    </div>
    <Dialog v-bind="Dialog" @responseDialog="responseDialog"></Dialog>
  </div>
</template>
<script>
import Dialog from "./DialogShowFileInfo.vue"
import { pushDownload } from "@/api/getData.js";
import { downloadPdf } from '@/utils/handle.js'
export default {
  components: {
    Dialog
  },
  props: {
    detail: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      Dialog: {
        dialogState: false,
        URL: '',
        fileType: '',
        title: ''
      },
      isPC: true
    }
  },
  mounted() {
    // 当前设备屏幕尺寸
    window.onresize = () => {
      let width = window.innerWidth
      this.isPC = width > 1000;
    }
    window.onresize()
  },
  methods: {
    //下载当前页面下编号为ref，日期为publication_date 的器械的说明书
    async Download(row) {
      downloadPdf(row.filepath)
      const { ifuCode, ebookId, ebookCode, revision, areaId, areaName, areaCode, groupCode, groupId, groupName, fileName, filepath } = row
      try {
        const data = await pushDownload({ ifuCode, ebookId, ebookCode, revision, areaId, areaName, areaCode, groupCode, groupId, groupName, fileName, filePath: filepath, type: "2" })
      } catch { }
    },
    //对说明书进行预览
    async preView(row) {
      let url = row.filepath;
      const { ifuCode, ebookId, ebookCode, revision, areaId, areaName, areaCode, groupCode, groupId, groupName, fileName, filepath } = row
      try {
        const data = await pushDownload({ ifuCode, ebookId, ebookCode, revision, areaId, areaName, areaCode, groupCode, groupId, groupName, fileName, filePath: filepath, type: "1" })
      } catch { }
      if (!this.isPC || window.navigator.msSaveBlob != undefined) {
        this.$router.push({
          path: "/ComPdf",
          query: {
            file: url,
          },
        });
        return false;
      }
      this.Dialog.URL = url;
      this.Dialog.title = row.productName;
      this.Dialog.dialogState = true;
      switch (url.slice(-4)) {
        case ".pdf":
          this.Dialog.fileType = "pdf";
          break;
        case ".mp3":
          this.Dialog.fileType = "mp3";
          break;
        case ".mp4":
          this.Dialog.fileType = "mp4";
          break;
      }
    },
    // 表头class 过滤
    headerTable({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 0 || columnIndex == 5) {
        return 'headerTable'
      }
    },
    headerTableLast({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 0) {
        return 'headerTable'
      } else if (columnIndex == 5) {
        return 'headerTableLast'
      }
    },
    // 关闭Dialog
    responseDialog(val) {
      if (typeof val == 'boolean') { this.Dialog.dialogState = false; return; };
    }
  },
}
</script>

<style lang="scss" scoped>
.btn-box {
  display: flex;

  .el-button {
    margin: 0;
  }
}

.main01 {
  border: 1px solid #ebeef5;

}

@media screen and (max-width: 769px) {}

@media screen and (min-width: 768px) {}

.moblie-show-list {
  background: white;
  font-family: PingFangSC-Regular, PingFang SC;

  .moblie-item+.moblie-item {
    border-top: 1px solid rgba(161, 160, 160, 0.171);
  }

  .moblie-item {
    font-size: 14px;
    line-height: 20px;
    color: #262626;
    display: flex;
    flex-direction: row;
    padding: 5px 10px;
    justify-content: space-around;

    div {
      flex: 1;
    }

    .moblie-title {
      font-weight: 400;
    }

    div+div {
      text-align: right;
    }
  }

  .handle {
    div+div {
      color: rgb(27, 154, 238);

      span {
        margin-left: 10px;
        display: inline-block;
        height: 100%;

        i {
          display: block;
          position: relative;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}
</style>
