import Vue from "vue";

import App from "./App.vue";
import router from "./router";
import store from "./store";

//global css
import "element-ui/lib/theme-chalk/index.css";
import 'lib-flexible/flexible.js';
import '@/assets/styles/index.css';

import ElementUI from "element-ui";

Vue.config.productionTip = false;

//使用element框架
Vue.use(ElementUI);

new Vue({
    router,
    store,
    render: (h) => h(App),
    created: function() {
        if (window.location.host === 'www.eifu.micro-tech.com.cn') {
          window.location.href = window.location.href.replace(window.location.host, 'eifu.micro-tech.com.cn');
        }
    }
}).$mount("#app");