<template>
    <div style="height: 500px; width: 100%">
        <div>
            <router-link to="/QueryArea" slot="left">
                <el-button icon="el-icon-arrow-left" circle></el-button>
            </router-link>
        </div>
        <audio controls="controls" style="height: 400px; width: 400px;text-align: center">
            <source :src="pageUrl" type="audio/mp3" />
        </audio>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                pageUrl: 'https://nwyx.obs.cn-east-2.myhuaweicloud.com/153149-movers-sample-listening-test-vol2.mp3'
            }
        },
        methods: {}
    }
</script>