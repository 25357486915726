import axios from "axios";

let URL = null;

if (process.env.NODE_ENV) {
    URL = process.env.VUE_APP_BASE_API
}

const service = axios.create({
    // url = baseURL + requestURL
    baseURL: URL,
    // baseURL: "http://47.101.187.182:8848",

    //配置请求超时时间
    timeout: 5000,
});

// console.log(process.env.VUE_APP_BASE_API);

export default service;