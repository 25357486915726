<template>
  <div class="USA">
    <el-container class="area-box">
      <!-- 通用组件,页面header -->
      <common-top :areaValue="areaValue"></common-top>
      <el-main ref="main">
        <!-- 广播 -->
        <div class="radio-box" v-if="test.length > 0">
          <ul>
            <div class="radio-icon"></div>
            <li v-for="(item,index) in test" :key="index" :class="index===textOptions?'radiofram':''"><span>{{item}}</span></li>
          </ul>
        </div>
        <div class="content">
          <span class="one">
            If the label on your product includes the symbol below, it means we are providing an electronic IFU in the markets where this is permitted.
          </span>
          <div>
            <span class="two" >
              <!-- <img
                src="../../assets/img/shuben2.png"
                style="background: #ffffff"
              /> -->
              <img
                class="consult"
                src="@/imgs/consult.jpg"
                style="background: #ffffff"
              />
              <!-- {{ buttoms[0].email }} -->
              eifu.micro-tech.com.cn
            </span>
          </div>
          <div class="one">
            In order to find the appropriate Instructions for Use for a Micro-Tech Corporation product, you can use the product name from the product label,
or please enter the product-specific IFU code from the website instruction page in the paper IFU in the field below. 
          </div>
          <!-- <div class="one">
            To locate the product name from the product label.</div>
          <div class="one">
            Or please enter the product-specific IFU code from the website instruction page in the paper  IFU in the field below. 
          </div> -->
          <span class="one">
            For a paper copy, provided at no cost and in seven calendar days, please click on the directory listing link. 
          </span>
          <el-popover trigger="hover" :popper-class="'content-class'">
            <el-link type="primary" slot="reference">Directory listing</el-link>
            <div class="Home-footer">
              <div class="footer">
                <div class="Home-footer-title" v-if="buttoms[0]">
                  {{ buttoms[0].address_title }}
                </div>
                <div class="Home-footer-content" v-if="buttoms[0]">
                  <div class="x003">
                    <p>{{ buttoms[0].address_place }}</p>
                    <p>{{ buttoms[0].address_nation }}</p>
                    <p>{{ buttoms[0].address_area }}</p>
                  </div>
                  <p class="relation">
                    <span>TEL:</span><span>{{ buttoms[0].tel }}</span>
                  </p>
                  <p class="relation">
                    <span>E-mail:</span><span>{{ buttoms[0].email }}</span>
                  </p>
                </div>
              </div>
              <div class="footer2 footer">
                <div class="Home-footer-title" v-if="buttoms[0]">
                  {{ buttoms[1].address_title }}
                </div>
                <div class="Home-footer-content" v-if="buttoms[0]">
                  <div class="x003">
                    <p>{{ buttoms[1].address_place }}</p>
                    <p>{{ buttoms[1].address_nation }}</p>
                    <p>{{ buttoms[1].address_area }}</p>
                  </div>
                  <p class="relation">
                    <span>TEL:</span><span>{{ buttoms[1].tel }}</span>
                  </p>
                  <p class="relation">
                    <span>E-mail:</span><span>{{ buttoms[1].email }}</span>
                  </p>
                </div>
              </div>
              <div class="footer3 footer">
                <div class="Home-footer-title" v-if="buttoms[0]">
                  {{ buttoms[2].address_title }}
                </div>
                <div class="Home-footer-content" v-if="buttoms[0]">
                  <div class="x003">
                    <p>{{ buttoms[2].address_place }}</p>
                    <p>{{ buttoms[2].address_nation }}</p>
                    <p>{{ buttoms[2].address_area }}</p>
                  </div>
                  <p class="relation">
                    <span>TEL:</span><span>{{ buttoms[2].tel }}</span>
                  </p>
                  <p class="relation">
                    <span>E-mail:</span><span>{{ buttoms[2].email }}</span>
                  </p>
                </div>
              </div>
            </div>
          </el-popover>
        </div>
        <div class="search-Title">
          <h4>Product name / IFU code</h4>
          <div class="search-Form">
            <el-form ref="form" :model="form" @submit.native.prevent>
              <el-form-item prop="RefNumber">
                <el-input
                  v-model="tolinks.markedId"
                  placeholder="Please enter keyword here."
                  @keyup.enter.native="submitForm()"
                ></el-input>
                <el-popover
                v-if="!this.tolinks.dialogState"
                  placement="bottom-start"
                  width="100%"
                  trigger="manual"
                  v-model="fuzzy"
                  :popper-class="'RefNumber-class'"
                >
                  <div class="number-item">
                    <div
                      class="for-item"
                      v-for="(item, index) in numberList"
                      :key="index"
                      @click="valueNumberCk(item)"
                    >
                      <span>&nbsp;&nbsp;&nbsp;{{ item | content }}</span>
                    </div>
                  </div>
                  <!--<p> content="这是一段内容,这是一段内容,这是一段内容,这是一段内容。"</p>-->
                  
                  <!--<el-button slot="reference">hover 激活</el-button>-->
                </el-popover>
                
              </el-form-item>
              <el-form-item>
                <div class="form007">
                  <el-button type="primary" @click="submitForm()" size="small"
                    >submit</el-button
                  >
                </div>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <!---->
        <div class="footer01" v-if="state === '1'">
          <SearchResult :details="details" @updateScroll="updateScroll"></SearchResult>
        </div>
      </el-main>
      <div style="flex-grow: 1;"></div>
      <div class="foot-under">
        <div class="one one-div">
          Adobe Acrobat Reader is required for viewing the IFU documents.<a
            href="https://get.adobe.com/reader/"
            title="DownLoad the free Acrobat Reader"
            style="color: #a5a2a2; border-bottom: 1px solid #a5a2a2"
            > Download the Free Adobe Acrobat Reader .
            <img
              src="../../assets/img/pdf.png"
              style="width: 0.4rem; height: 0.4rem"
          /></a>
        </div>
        <div class="one one-div">
          DISCLAIMER: All contents copyright © Micro-Tech unless otherwise noted. This site was written for health care professionals only.
        </div>
      </div>
    </el-container>
    <dialogs v-bind="tolinks" @responseDialog="responseDialog"></dialogs>
  </div>
</template>
<script>
// 模糊查询接口，数据节流防抖
function trigger() {
  let timer = null;
  return function (getfuzzy) {
    if (timer) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        getfuzzy.apply(this);
      }, 1000);
    } else {
      timer = setTimeout(() => {
        // 首次触发
        getfuzzy.apply(this);
      }, 100);
    }
  };
}
const olayTrigger = trigger();
import CommonTop from "@/components/CommonTop";
import { getDetail,fuzzyList,announcement,searchCount } from "@/api/getData.js";
import SearchResult from "./Components/SearchResult";
import dialogs from "./Components/Dialog.vue";
export default {
  name: "UsaChild",
  components: {
    dialogs,
    CommonTop,
    SearchResult,
  },
  // 过滤 - 产品备注存在则显示在括号中，否则单独显示产品名称
  filters:{
    content(item){
      if(item.productComment){
        return item.productName  +'('+ item.productComment +')';
      }else{
        return item.productName 
      }
    }
  },
  /*页面信息缓存路由钩子 - 详情页
  * 针对移动端h5pdf新页面，用于保留用户操作记录
  * 当用户通过详情页进入pdf页面，此钩子函数将保留操作记录
  * 当用户通过详情页进入首页将本页面的路由缓存清除
  */
  beforeRouteLeave(to, from, next) {
    if (to.name == "Home" && from.name == "QueryArea") {
      from.meta.keepAlive = false;
    } else {
      from.meta.keepAlive = true;
    }
    next();
  },
  // 页面缓存后重新进入触发钩子函数
  activated() {
    this.areaValue = this.$route.query.area;
    this.areaCode = this.$route.query.areaCode;
    if(!this.timer){
      this.test && this.radio()
    }
  },
  // 页面离开清除运行中的定时器，防止内存泄漏
  deactivated(){
    clearTimeout(this.timer)
    // console.log(this.timer)
    this.timer = null
  },
  // 组件销毁钩子函数
  destroyed(){
    clearTimeout(this.timer)
    // console.log(this.timer)
    this.timer = null
  },
  data() {
    return {
      state: "0",
      // 广播列表
      test:[],
      // 当前广播
      textOptions:0,
      // 定时器
      timer:null,
      // test
      buttoms: [
        {
          id: 1,
          address: "Nation Hi-Tech Zone, NanJing,China,210032",
          tel: "+86-25-58609879，58646393",
          email: "info@micro-tech.com.cn",
          address_title: "Micro-Tech (NANJING) CO., LTD.",
          address_nation: "National Hi-Tech Industrial Development Zone",
          address_area: "Nanjing 210032, Jiangsu Province, PRC",
          address_place: "No. 10, Gaoke Third Road, Nanjing",
        },
        {
          id: 2,
          address: "Nation Hi-Tech Zone, NanJing,China,210032",
          tel: "+49 (0)211 73 27 626-0",
          email: "contact@micro-tech-europe.com",
          address_title: "Micro-Tech Europe GmbH.",
          address_nation: "40472 Düsseldorf, Germany",
          address_area: "",
          address_place: "Mündelheimer Weg 36",
        },
        {
          id: 3,
          address: "Nation Hi-Tech Zone, NanJing,China,210032",
          tel: "(+1) 877 552 4027",
          email: "eifu@mtendoscopy.com",
          address_title: "Micro-Tech USA Inc.",
          address_nation: "Ann Arbor, MI 48104 USA",
          address_area: "",
          address_place: "2855 Boardwalk Drive",
        },
      ],
      area: "",
      form: {
        RefNumber: "",
      },
      tolinks: {
        dialogState: false,
        markedId: "",
        market: "",
      },
      // 搜索状态
      searchStatus:false,
      searchName:'',
      // end
      fuzzy: false,
      fuzzy1: true,
      //    搜索事件开启
      details: [],
      areaValue: this.$route.query && this.$route.query.area,
      areaCode : this.$route.query && this.$route.query.areaCode,
      numberList: [],
      selectFuzzyResultFlag: false,
    };
  },
  watch: {
    "tolinks.markedId": function (newName, oldName) {
      if(this.searchStatus && this.searchName &&  this.searchName.length === 0){this.searchName = newName}else{this.searchStatus = false;this.searchName = ''}
      if (this.tolinks.markedId == "") this.fuzzy = false;
      if (!newName || newName.length < 3 || this.tolinks.markedId === "") {this.selectFuzzyResultFlag = false; return;}
      if((oldName && oldName.length >= 3) && newName.indexOf(oldName) != -1 && this.numberList.length > 0 && !this.fuzzy) {this.selectFuzzyResultFlag = false; return;}
      if(this.selectFuzzyResultFlag === true) {this.selectFuzzyResultFlag = false; return;}
      olayTrigger(this.getfuzzy);
      this.selectFuzzyResultFlag = false;
    },
  },
  methods: {
    //页面跳转
    // JumpLink(url) {
    //   this.url = url;
    //   window.location.href = "https://" + this.url;
    //   console.log("跳转的路径是" + url);
    // },
    //表单提交
    submitForm() {
      if (!this.tolinks.markedId) return;
      this.fuzzy = false;
      this.tolinks.dialogState = true;
      this.pushSearchCount({areaCode:this.areaCode,keyword:this.tolinks.markedId})
    },
    // 获取数据
    async getDetailList(querydata) {
      let exp = /(^\w+)\((.+)\)$/i
      let params = {}
      if(typeof querydata == 'string'){
        // 判断是否有输入内容
        if(exp.test(querydata)){
          let paramsList = querydata.match(exp)
          params.productName = paramsList[1]
          params.productComment = paramsList[2]
        }else{
          /* 检测当前是否在通过 模糊查询快捷输入后是否再次通过按钮进行搜索
          *  如果是就以productName方式继续请求，否则就以ifuCode请求
          */
          if(this.searchStatus && this.searchName == querydata){
            params.productName = querydata
          }else{
            params.ifuCode = querydata
          }
        }
      }else{
        if(querydata.status){
          params.productName = querydata.productName
        }else{
          params = querydata
        }
      }
      params.areaCode = this.areaCode;
      try {
        const data = await getDetail(params);
        if (data.data.code == 200) {
          if(data.data.rows && data.data.rows.length >0){
            this.details = data.data.rows;
            this.details[0].areaCode = this.areaCode;
            this.state = "1";
          }else{
            this.details = []
            this.state = "0";
            this.$message({
              message: "Please enter a valid 'Product name / IFU code'. If you search by product name, please enter at least 13 characters, and select one of the items according to the prompt.",
              type: "warning",
            })
            return;
          }
        } else {
          this.$message.error(data.data.msg);
        }
      } catch {
        // console.error("列表查询请求异常！");
      }
    },

    // input模糊查询
    async getfuzzy() {
      let params = null;
      let exp = /(^\w+)\((.+)/i
      if(exp.test(this.tolinks.markedId)){
          let paramsList = this.tolinks.markedId.match(exp)
          params = paramsList[1]
        }else{
          if (this.tolinks.markedId && this.tolinks.markedId.length < 3) return;
          params = this.tolinks.markedId
        }
      try {
        const data = await fuzzyList(params,this.areaCode);
        if (data.data.code == 3) {
          this.numberList = [];
          this.fuzzy = false;
          return false;
        }
        if(!data.data.rows || data.data.rows.length === 0){
          this.fuzzy = false;
        }else{
          this.fuzzy = true;
        }
        this.numberList = data.data.rows;
      } catch {
        // console.error("模糊查询请求异常！");
      }
    },
    // 获取公告数据
    async getAnnouncement(){
      try{
        const data = await announcement(this.areaCode)
        if(data.data.code == 200 && data.data.rows && data.data.rows.length >0){
          let textList = []
          data.data.rows.forEach(item => {
            const {content} = item
            textList.push(content)
          });
          this.test = textList
        }
      }catch{
        // console.error('公告数据获取失败')
      }
    },
    // 搜索量统计
    async pushSearchCount(data){
      try{
        const res = await searchCount(data)
        // console.log(res)
      }catch{

      }
    },
    //  Number点击事件
    valueNumberCk(val) {
      // console.log(val)
      this.selectFuzzyResultFlag = true;
      if(val && val.productComment && val.productComment.length>0){
        this.tolinks.markedId = val.productName +"("+ val.productComment+")"
        this.getDetailList({productName:val.productName,productComment:val.productComment});
      }else{
        this.tolinks.markedId = val.productName
        this.getDetailList({productName:val.productName,status:true});
      }
      this.searchStatus = true
      this.pushSearchCount({areaCode:this.areaCode,keyword:this.tolinks.markedId})
      this.fuzzy = false;
    },
    responseDialog(val) {
      if (typeof val == "boolean") {
        this.tolinks.dialogState = false;
        return;
      }
      this.getDetailList(this.tolinks.markedId);
      this.tolinks.dialogState = false;
    },
    // 广播计时
    radio(){
      let total = this.test.length;
      this.timer = setTimeout(()=>{
        this.textOptions++
        if(this.textOptions >= total){
          this.textOptions = 0;
        }
        this.radio()
      },8000)
    },
    // 数据展示后更订页面高度
    updateScroll(){
      setTimeout(()=>{
        let scrollTop = this.$refs.main.$el.scrollHeight  - this.$refs.main.$el.offsetHeight
        this.$refs.main.$el.scrollTop = scrollTop
      },500)
      
    },
  }, 
  //页面渲染完成调用方法获取数据
  mounted() {
    this.test && this.radio()
    this.getAnnouncement()
  },
  // vue挂在完成执行的方法
  created(){
    if(this.$route.query.area && this.$route.query.areaCode){
      this.areaValue = this.$route.query.area;
      this.areaCode = this.$route.query.areaCode;
    }else{
      this.$router.push('/')
    }
  }
};
</script>

<!-- <style>
.RefNumber-class{
  max-height: 200px;
  max-width: 300px;
  overflow: auto;
}
</style> -->

<style lang="scss" scoped>
.area-box {
  overflow-y: auto;
  height: 100%;
  flex-direction: column;
}
.for-item {
  cursor: pointer;
  font-size: 16px;
}
.el-form-item {
  margin-bottom: 0;
}
a:link,
a:visited {
  text-decoration: none;
  color: #000000;
  background: transparent;
  border: 1px solid #000000;
  font-weight: bold;
}
.two {
  background: #000000;
  display: inline-block;
  color: #ffffff;
  font-size: 0.3rem;
}
.four {
  font-weight: bold;
  color: #00478b;
}
// 链接样式
.el-main .el-link {
  font-size: 0.3rem;
  font-weight: bold;
  color: #00478b;
  border-bottom: 1px solid #00478b;
}
.el-main {
  padding: 0;
  overflow: visible;
  padding-top: .2rem;
}
.el-main .el-link:hover {
  color: #00478b;
}
.el-link.el-link--default:after,
.el-link.el-link--primary.is-underline:hover:after,
.el-link.el-link--primary:after {
  border-color: #00478b;
}
.el-link.is-underline:hover::after {
  border-bottom: 1px solid #00478b;
}
.search-Form .el-form {
  display: flex;
  flex-direction: row;
}
.search-Form .el-form .el-form-item:first-child {
  flex-grow: 2;
}
.search-Form .el-form .el-form-item:last-child {
  flex-grow: 1;
}
// 子组件背景色
.footer01 {
  background: #f9f9f9;
}
.consult {
  width: 0.6rem;
  position: absolute;
  left: 0.2rem;
  top: 50%;
  transform: translateY(-50%);
}

// 广播样式调整
.radio-box{
  ul{
    list-style: none;
    color: #4F5051;
    font-size: 14px;
    line-height: .73rem;
    position: relative;
    padding-bottom: .7rem;
    overflow: hidden;
    background-color: #EDF4FA;
    .radio-icon{
      position: absolute;
      height: 100%;
      width: 20px;
      left: 10px;
      top: 0;
      background-image: url('../../imgs/radio.png');
      background-size: 80% 45%;
      background-repeat: no-repeat;
      background-position: center;
      z-index: 889;
    }
    li{
      top: 50px;
      position: absolute;
      display: inline-block;
      padding-left: 35px;
      width: calc(100% - 35px);
      height: 100%;
      span{
        vertical-align: middle;
      }
    }
  }
}
.radiofram{
  animation: radio 8s ease-out;
  animation-iteration-count:infinite;
  z-index: 888;
}
@keyframes radio {
  0%{
    transform: translateY(0);
  }
  5%{
    transform: translateY(-50px);
  }
  95%{
    transform: translateY(-50px);
  }
  100%{
    transform: translateY(-100px);

  }
}
@media all and (min-width: 769px) {
  .USA {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
  }
  
  // 主体宽度限制
  .el-main .content {
    padding: 0.1rem 2rem;
    font-family: PingFangSC-Semibold, sans-serif;
  }
  .el-main .search-Title {
    padding: 0.1rem 2rem;
    font-family: PingFangSC-Semibold, sans-serif;
  }
  .el-main .footer01 {
    padding: 0.1rem 2rem;
    font-family: PingFangSC-Semibold, sans-serif;
  }
  .one-div {
    padding: 0.1rem 2rem;
    font-family: PingFangSC-Semibold, sans-serif;
  }
  .radio-box{
    padding: 0.1rem 2rem;
    font-family: PingFangSC-Semibold, sans-serif;
  }
  .number-item {
    min-width: 10.8rem;
  }
  .content {
    flex-direction: column;
    font-size: 0.33rem;
    color: #4d4e4f;
    line-height: 28px;
    color: #4D4E4F;
    .one{
      margin-bottom: .18rem;
    }
  }
  .two {
    font-size: 0.35rem;
    position: relative;
    margin: .25rem 0;
    padding: 0.1rem 0.2rem;
    padding-left: 0.9rem;
  }
  .four {
    font-size: 15px;
  }
  .search-Title {
    font-size: 18px;
  }
  .content_ct {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .search-Form {
    margin: 0.3rem 0;
  }

  i {
    font-size: 20px;
  }
  .foot-under {
    // height: 80px;
    background: #000000;
    width: 100%;
    color: #a5a2a2;
    font-size: 15px;
    padding: .1rem 0;
    font-family: PingFangSC-Semibold, sans-serif;
  }
}
@media all and (min-width: 1200px) {
   .el-main .content {
    padding: 0.1rem 4.7rem;
    font-family: PingFangSC-Semibold, sans-serif;
  }
  .el-main .search-Title {
    padding: 0.1rem 4.7rem;
    font-family: PingFangSC-Semibold, sans-serif;
  }
  .el-main .footer01 {
    padding: 0.1rem 4.7rem;
    font-family: PingFangSC-Semibold, sans-serif;
  }
  .one-div {
    padding: 0.1rem 4.7rem;
    font-family: PingFangSC-Semibold, sans-serif;
  }
  .radio-box{
    padding: 0.1rem 4.7rem;
    font-family: PingFangSC-Semibold, sans-serif;
  }
}
@media screen and (max-width: 768px) {
  .USA,.area-box{
    height: 100%;
  }
  //移动端边框留白
  .content,.search-Title,.footer01{
    padding: 10px;
  }
  .number-item {
    min-width: 8.5rem;
  }
  .one-div {
    padding: 0.1rem .3rem;
  }
  .content {
    // margin-top: 0.5rem;
    flex-direction: column;
    font-size: 0.35rem;
  }
  .search-Title {
    font-size: 0.4rem;
  }
  .one {
    line-height: 2;
  }
  .four {
    font-size: 0.35rem;
  }
  .content_title {
    font-weight: bold;
    color: #0b5f95;
  }
  .two {
    font-size: 0.38rem;
    position: relative;
    padding: 0.1rem 0.2rem;
    padding-left: 0.8rem;
  }
  .content_ct {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  i {
    font-size: 0.4rem;
  }
  .foot-under {
    // height: 3.5rem;
    background: #000000;
    width: calc(100% - 1rem);
    color: #a5a2a2;
    font-size: 0.35rem;
    padding: 0.2rem 0.5rem;
    margin: 0 auto;
  }
  .search-Form .el-form .el-form-item:first-child {
    flex: 5;
  }
  .search-Form .el-form .el-form-item:last-child {
    flex: 1;
  }
}

</style>
