<template>
  <div>
    <el-dialog
    title="Choose your Country or Region"
    :visible="dialogState"
    top="30vh"
    custom-class="rundialog"
    :append-to-body="true"
    :before-close="cancel"  
    center>
        <span>Are you sure you want to proceed to {{area}}?</span>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="toMarket(areaCode,area)">PROCEED</el-button>
            <el-button @click="cancel">CANCEL</el-button>
        </span>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    props:{
      dialogState:{
        type:Boolean,
        default:false
      },
      areaCode:{
        type:String,
        default:''
      },
      area:{
        type:String,
        default:''
      }
    },
    data() {
      return {
        centerDialogVisible: true
      };
    },
    methods:{
        cancel(){
            this.$emit('cancelDialog',false)
        },
        toMarket(id,area){
            // 路由跳转
            this.cancel();
            this.$router.push({
              path: "/QueryArea",
              query: { areaCode: id ,area: area },
            });
        }
    }
  };
</script>